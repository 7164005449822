import React, { useState } from 'react'
import { Upload, Download, ArrowUp, Info, Shield, Clock, Settings, Share2, DollarSign } from 'lucide-react'
import { Alert } from "./Alert"

const Landing = () => {

    const [showTips, setShowTips] = useState(false)

    const getCurrentDate = () => {
        const date = new Date()
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })
    }

    return (
        <div className="max-w-4xl mx-auto p-6">
            {/* Updated About Section */}
            <section className="mt-5 bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-8 border border-blue-100">
                <h2 className="text-2xl font-bold mb-6 text-blue-600">About Our Image Compressor</h2>
                <div className="space-y-4 text-gray-600">
                    <p>
                        Our free image compression tool helps you optimize images for web use while maintaining
                        quality. Perfect for website owners, developers, and content creators who need to reduce
                        image file sizes without compromising visual quality.
                    </p>
                    <p>
                        Key features:
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Instant compression with quality preservation</li>
                        <li>Support for various image formats</li>
                        <li>No registration required</li>
                        <li>Secure and private - all processing happens in your browser</li>
                        <li>Free to use with no limitations</li>
                    </ul>
                </div>
            </section>
            {/* New Statistics Section */}
            <section className="mt-12 grid md:grid-cols-3 gap-6">
                <div className="bg-white p-6 rounded-lg shadow-sm border border-blue-100 text-center">
                    <Shield className="w-8 h-8 text-blue-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-lg mb-1">100% Secure</h3>
                    <p className="text-gray-600">Browser-based processing</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-blue-100 text-center">
                    <Clock className="w-8 h-8 text-blue-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-lg mb-1">Fast Processing</h3>
                    <p className="text-gray-600">Optimize in seconds</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-blue-100 text-center">
                    <DollarSign className="w-8 h-8 text-blue-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-lg mb-1">100% Free</h3>
                    <p className="text-gray-600">Free for everyday usage</p>
                </div>
            </section>



            {/* New Tips & Tricks Section */}
            <section className="mt-12 bg-gradient-to-br from-white to-green-50 rounded-lg shadow-sm p-8 border border-green-100">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-green-600">Image Optimization Tips</h2>
                    <button
                        onClick={() => setShowTips(!showTips)}
                        className="text-green-600 hover:text-green-700"
                    >
                        {showTips ? 'Hide Tips' : 'Show Tips'}
                    </button>
                </div>

                {showTips && (
                    <div className="space-y-4 text-gray-600">
                        <div className="bg-white p-4 rounded-lg border border-green-100">
                            <h3 className="font-semibold mb-2">1. Choose the Right Format</h3>
                            <p>Use JPEG for photographs, PNG for images with text or transparency, and WebP for modern browsers.</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-green-100">
                            <h3 className="font-semibold mb-2">2. Optimal Dimensions</h3>
                            <p>Resize images to their display size before compression for best results.</p>
                        </div>
                        {/* <div className="bg-white p-4 rounded-lg border border-green-100">
                            <h3 className="font-semibold mb-2">3. Batch Processing</h3>
                            <p>Save time by optimizing multiple images at once with our bulk processing feature.</p>
                        </div> */}
                    </div>
                )}
            </section>


            {/* New Share Section */}
            {/* <section className="mt-12 text-center">
                <h2 className="text-xl font-semibold mb-4">Share with Friends</h2>
                <div className="flex justify-center gap-4">
                    <button className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors">
                        <Share2 className="w-5 h-5" />
                    </button>
                </div>
            </section> */}

            {/* Footer */}
            <footer className="mt-16 pt-8 border-t border-gray-200">
                <div className="grid md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="font-semibold mb-4">ImageOptimizer</h3>
                        <p className="text-gray-600 text-sm">
                            Professional image optimization tools for developers and content creators.
                        </p>
                    </div>
                    {/* <div>
                        <h3 className="font-semibold mb-4">Quick Links</h3>
                        <ul className="space-y-2 text-sm text-gray-600">
                            <li>Documentation</li>
                            <li>API Access</li>
                            <li>Support</li>
                        </ul>
                    </div> */}
                    {/* <div>
                        <h3 className="font-semibold mb-4">Legal</h3>
                        <ul className="space-y-2 text-sm text-gray-600">
                            <li>Privacy Policy</li>
                            <li>Terms of Service</li>
                            <li>Cookie Policy</li>
                        </ul>
                    </div> */}
                </div>
                <div className="mt-8 pt-8 border-t border-gray-200 text-center text-sm text-gray-600">
                    © {new Date().getFullYear()} ImageOptimizer. All rights reserved.
                </div>
            </footer>

            {/* Keep existing Back to Top button */}
        </div>
    )
}

export default Landing