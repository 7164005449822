import ImageOptimizer from './components/ImageOptimizer';
import { Analytics } from "@vercel/analytics/react"
import Landing from './components/Landing';
import Contact from './components/Contact';
import { Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';

function App() {
  return (
    <BrowserRouter>
      <div className="max-w-4xl mx-auto p-6">
        <Analytics></Analytics>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/tool" element={<ImageOptimizer />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/contact" element={<Contact />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App