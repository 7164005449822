import React, { useState, useRef, useEffect } from 'react'
import { Upload, Download, ArrowUp } from 'lucide-react'

const ImageOptimizer = () => {
  const [originalImage, setOriginalImage] = useState(null)
  const [optimizedImage, setOptimizedImage] = useState(null)
  const [originalSize, setOriginalSize] = useState(0)
  const [optimizedSize, setOptimizedSize] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showBackToTop, setShowBackToTop] = useState(false)
  const canvasRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    if (!file) return

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file')
      return
    }

    setOriginalSize(file.size)
    setError('')

    const reader = new FileReader()
    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        setOriginalImage(img)
        optimizeImage(img)
      }
      img.src = event.target.result
    }
    reader.readAsDataURL(file)
  }

  const optimizeImage = (img) => {
    setLoading(true)
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    let width = img.width
    let height = img.height
    const maxDimension = 1200

    if (width > maxDimension || height > maxDimension) {
      if (width > height) {
        height = Math.round((height * maxDimension) / width)
        width = maxDimension
      } else {
        width = Math.round((width * maxDimension) / height)
        height = maxDimension
      }
    }

    canvas.width = width
    canvas.height = height

    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, width, height)
    ctx.drawImage(img, 0, 0, width, height)

    canvas.toBlob(
      (blob) => {
        setOptimizedImage(URL.createObjectURL(blob))
        setOptimizedSize(blob.size)
        setLoading(false)
      },
      'image/jpeg',
      0.7
    )
  }

  const downloadOptimizedImage = () => {
    const link = document.createElement('a')
    link.href = optimizedImage
    link.download = 'optimized-image.jpg'
    link.click()
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Updated Ad Space with gradient background */}
      <div className="w-full h-24 bg-gradient-to-r from-blue-50 to-purple-50 mb-6 flex items-center justify-center border-2 border-dashed border-blue-200">
        <p className="text-gray-500">Advertisement Space</p>
      </div>

      <div className="space-y-6">
        {/* Updated Header with gradient text */}
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-2 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Image Compressor
          </h1>
          <p className="text-gray-600">Optimize your images for web use instantly</p>
        </div>

        {/* Updated Upload Section with subtle gradient */}
        <div className="border-2 border-dashed border-blue-200 rounded-lg p-8 text-center bg-gradient-to-b from-white to-blue-50">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="imageInput"
          />
          <label
            htmlFor="imageInput"
            className="cursor-pointer inline-flex flex-col items-center space-y-2 hover:scale-105 transition-transform duration-200"
          >
            <Upload className="w-12 h-12 text-blue-500" />
            <span className="text-blue-600 font-medium">Click to upload an image</span>
            <span className="text-sm text-blue-400">or drag and drop</span>
          </label>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {originalImage && (
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <h3 className="font-semibold text-blue-600">Original</h3>
              <div className="border border-blue-100 rounded-lg p-4 bg-white shadow-sm">
                <img
                  src={originalImage.src}
                  alt="Original"
                  className="max-w-full h-auto"
                />
                <p className="mt-2 text-sm text-gray-600">
                  Size: {(originalSize / 1024).toFixed(2)} KB
                </p>
              </div>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold text-purple-600">Optimized</h3>
              <div className="border border-purple-100 rounded-lg p-4 bg-white shadow-sm">
                {loading ? (
                  <div className="flex items-center justify-center h-40">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                  </div>
                ) : (
                  optimizedImage && (
                    <>
                      <img
                        src={optimizedImage}
                        alt="Optimized"
                        className="max-w-full h-auto"
                      />
                      <p className="mt-2 text-sm text-gray-600">
                        Size: {(optimizedSize / 1024).toFixed(2)} KB
                      </p>
                      <p className="text-sm text-green-500 font-medium">
                        Saved: {(((originalSize - optimizedSize) / originalSize) * 100).toFixed(1)}%
                      </p>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {optimizedImage && !loading && (
          <div className="text-center">
            <button
              onClick={downloadOptimizedImage}
              className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity duration-200 inline-flex items-center space-x-2 shadow-md"
            >
              <Download className="w-4 h-4" />
              <span>Download Optimized Image</span>
            </button>
          </div>
        )}

        <canvas ref={canvasRef} className="hidden" />

        

        {/* Updated FAQ Section */}
        <section className="mt-8 bg-gradient-to-br from-white to-purple-50 rounded-lg shadow-sm p-8 border border-purple-100">
          <h2 className="text-2xl font-bold mb-6 text-purple-600">Frequently Asked Questions</h2>
          <div className="space-y-6">
            <div>
              <h3 className="font-semibold text-lg mb-2">How does the compression work?</h3>
              <p className="text-gray-600">
                Our tool uses smart compression algorithms to reduce image file size while maintaining
                optimal visual quality. Images are processed entirely in your browser, ensuring your
                files remain private and secure.
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-2">What image formats are supported?</h3>
              <p className="text-gray-600">
                We support common web image formats including JPEG, PNG, and WebP. The compressed
                output is delivered in JPEG format for optimal web performance.
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-2">Is there a file size limit?</h3>
              <p className="text-gray-600">
                While there's no strict file size limit, we recommend keeping input images under 10MB
                for optimal performance. Larger files may take longer to process.
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-2">Is my data secure?</h3>
              <p className="text-gray-600">
                Yes! All image processing happens directly in your browser. Your images are never
                uploaded to our servers, ensuring complete privacy and security.
              </p>
            </div>
          </div>
        </section>

        {/* Updated Bottom Ad Space */}
        <div className="w-full h-24 bg-gradient-to-r from-purple-50 to-blue-50 flex items-center justify-center border-2 border-dashed border-purple-200">
          <p className="text-gray-500">Advertisement Space</p>
        </div>
      </div>

      {/* Updated Back to Top Button */}
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-gradient-to-r from-blue-600 to-purple-600 text-white p-3 rounded-full shadow-lg hover:opacity-90 transition-opacity duration-200"
          aria-label="Back to top"
        >
          <ArrowUp className="w-6 h-6" />
        </button>
      )}
    </div>
  )
}

export default ImageOptimizer