import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="mb-8 flex justify-between items-center">
      <div
        className="text-xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent cursor-pointer"
        onClick={() => navigate('/')}
      >
        ImageOptimizer
      </div>
      <div className="flex gap-4">
        <button className="text-gray-600 hover:text-blue-600 transition-colors" onClick={() => navigate('/tool')}>
          Tool
        </button>
        {/* <button className="text-gray-600 hover:text-blue-600 transition-colors" onClick={() => navigate('/blog')}>
          Blog
        </button> */}
        {/* <button className="text-gray-600 hover:text-blue-600 transition-colors" onClick={() => navigate('/contact')}>
          Contact
        </button> */}
      </div>
    </nav>
  );
};

export default Navbar;